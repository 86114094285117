import { rhythm } from "../../utilities/typography"
import styled from "styled-components"

const BlockQuotePost = styled.blockquote`
  margin-left: 0;
  margin-right: 0;
  border-left: 10px solid ${(props) => props.theme.colors.lci_blue};
  padding-left: ${rhythm(1)};
`

export default BlockQuotePost
