import React from "react"
import _ from "lodash/fp"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import Box from "@material-ui/core/Box"
import HRPost from "../components/richText/HR"
import BlockQuotePost from "../components/richText/Blockquote"
import StyledLink from "../components/StyledLink"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Typography } from "@material-ui/core"

const rendererOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData } = node.data.target
      if (!gatsbyImageData) {
        // asset is not an image
        return null
      }

      const description = _.get(["data", "target", "description"], node, "")
      const title = _.get(["data", "target", "title"], node, "")

      return (
        <Box m={4}>
          <GatsbyImage image={getImage(gatsbyImageData)} title={title} alt={title} />
          {description !== "" && (
            <Box m={1}>
              <Typography variant={"body2"}>{description}</Typography>
            </Box>
          )}
        </Box>
      )
    },
    [BLOCKS.HR]: () => <HRPost />,
    [BLOCKS.QUOTE]: (node, children) => {
      return <BlockQuotePost {...node}>{children}</BlockQuotePost>
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return <StyledLink href={node.data.uri}>{children}</StyledLink>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Typography
          variant={"body1"}
          style={{
            fontSize: "1.1rem",
            lineHeight: 1.7,
            color: "rgba(14, 30, 37, 0.8)",
          }}
          gutterBottom
        >
          {children}
        </Typography>
      )
    },
  },
}

export default rendererOptions
