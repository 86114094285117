import { rhythm } from "../../utilities/typography"
import styled from "styled-components"

const HR = styled.hr`
  background: ${(props) => props.theme.colors.lci_yellow};
  width: ${rhythm(5)};
  height: 1px;
  margin: auto;
  margin-top: ${rhythm(2)};
  margin-bottom: ${rhythm(2)};
`

export default HR
